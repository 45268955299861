<template>
  <div>
    <ClientOnly>
      <Login></Login>
    </ClientOnly>
  </div>
</template>

<script setup>
useHead({
  title: 'Login / Signup - SIMMER.io',
});
</script>

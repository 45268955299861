<template>
  <v-container>
    <v-card class="mx-auto mt-5" max-width="500">
      <v-card-title class="text-center">Sign In / Sign Up</v-card-title>
        <ClientOnly>
          <LoginImpl></LoginImpl>
        </ClientOnly>
    </v-card>
  </v-container>
</template>
<script setup lang="ts">
</script>
<template>
  <v-card-text class="text-h6">Create a username</v-card-text>
  <v-card-text>This is your public username and will be used in the URL for any games you upload.</v-card-text>
  <v-card-text>ex: {{displayUrl}}</v-card-text>
  <v-card-text>
    <v-form @submit.prevent="submitForm">
      <v-text-field
        v-model="username"
        label="New Username"
        required
        :error-messages="usernameError"
      ></v-text-field>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="logout" class="mt-2" :disabled="loading">
          Sign Out
        </v-btn>
        <v-btn type="submit" color="primary" class="mt-2" :disabled="loading || !!usernameError">
          Create Username
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
      <v-alert
          v-if="error || formError"
          type="error"
          class="mt-3"
      >
        {{ error || formError }}
      </v-alert>
      <v-alert
          v-if="success"
          type="success"
          class="mt-3"
      >
        {{ success }}
      </v-alert>
    </v-form>
  </v-card-text>
</template>

<script setup>
import { ref, watch } from 'vue'
import { debounce } from '~/helpers/helpers'
import {useDatabaseValue} from "~/composables/useDatabaseValues.js";
import {getCurrentSite} from "~/helpers/whitelabel.js";
import {update} from "firebase/database";
import {dbRef} from "~/helpers/dbRef.js";
import callFirebaseFunction from "~/helpers/callFirebaseFunction.js";

const auth = useFirebaseAuth();

const db = useDatabase();
const username = ref('');
const loading = ref(false);
const usernameError = ref('');
const formError = ref('');
const formSuccess = ref('');
const domain = useCurrentDomain();
const firebaseUser = useCurrentUser();
const DEFAULT_DISPLAY_URL = `${domain}/@username/my-game`;
const displayUrl = ref(DEFAULT_DISPLAY_URL);
const emit = defineEmits(['onSuccess']);

const success = ref('');
const error = ref('');

// Split validation into immediate and async checks
const validateImmediateRules = (value) => {
  if (value.length < 3 || value.length > 16) {
    displayUrl.value = DEFAULT_DISPLAY_URL;
    return 'Username must be between 3 and 16 characters'
  }
  if (!/^[a-zA-Z0-9_]+$/.test(value)) {
    displayUrl.value = DEFAULT_DISPLAY_URL;
    return 'Username can only contain letters, numbers, and underscores'
  }
  return ''
}

const validateUsernameExists = async (value) => {
  const alreadyExists = await useDatabaseValue(`usernames/${value.toLowerCase()}`)
  if (alreadyExists) {
    displayUrl.value = DEFAULT_DISPLAY_URL;
    return 'Username already exists'
  }
  displayUrl.value = `${domain}/@${value.toLowerCase()}/my-game`
  return ''
}

// Update the watch to run immediate validation first
watch(username, (newValue) => {
  const immediateError = validateImmediateRules(newValue)
  usernameError.value = immediateError

  if (!immediateError) {
    debouncedValidate(newValue)
  }
})

// Update the debounced function to only check existence
const debouncedValidate = debounce(async (value) => {
  usernameError.value = await validateUsernameExists(value)
}, 1000)

watch(username, (newValue) => {
  debouncedValidate(newValue)
})

const createUser = async (username) => {
  const uid = firebaseUser.value.uid;
  const updates = {
    [`users/${uid}`]: {
      username: username,
      signupSite: getCurrentSite()
    },
    [`usernames/${username.toLowerCase()}`]: {
      uid
    }
  }
  try{
    const topDbRef = dbRef(db, '/')
    await update(topDbRef, updates);
  }
  catch (error) {
    formError.value = error.message || 'Unknown error';
    console.error('Error creating user:', error)
  }
}


const logout = async () => {
  await auth.signOut()
  await navigateTo('/')
}

const validateUsername = async (value) => {
  // First run immediate validation
  const immediateError = validateImmediateRules(value)
  if (immediateError) {
    return immediateError
  }

  // If immediate validation passes, check for existence
  return await validateUsernameExists(value)
}

const submitForm = async () => {
  formError.value = '';
  formSuccess.value = '';
  if (username.value) {
    loading.value = true
    try {
      // Perform immediate validation before submission
      const validationResult = await validateUsername(username.value)
      if (validationResult) {
        usernameError.value = validationResult
        loading.value = false
      }
      await createUser(username.value);
      formSuccess.value = `Username ${username.value} created successfully`;


    } catch (error) {
      // Handle error
      formError.value = error.message
    } finally {
      loading.value = false
    }

    try{
      loading.value = true
      const authUserResult = await callFirebaseFunction('email_auth_user', {template_name: 'welcome'});
      console.log(authUserResult.data);
    }
    catch(err){
      console.log(err);
    } finally {
      loading.value = false
    }

    try{
      loading.value = true;
      const eoAddResult = await callFirebaseFunction('email_octopus_add');
      console.log(eoAddResult.data);
    }
    catch(err){
      console.log(err);
    } finally {
      loading.value = false
    }

    emit('onSuccess');
  }
}
</script>
<template>
  <v-card-text class="text-h6">Email verification needed</v-card-text>
  <v-card-text>An email was sent to {{currentUser.email}}. Please click the link to verify your email. Once you've clicked the link, come back here, and click "check verification".</v-card-text>
  <v-card-actions class="justify-center">
    <v-btn @click="sendVerificationEmail" :disabled="isResendDisabled">
      {{ isResendDisabled ? `Resend in ${countdown}s` : 'Resend Email' }}
    </v-btn>
    <v-btn @click="checkVerification">Check Verification</v-btn>
    <v-btn @click="emit('onSignOut')">Sign Out</v-btn>
  </v-card-actions>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { sendEmailVerification } from "firebase/auth";

const currentUser = useCurrentUser();
const emit = defineEmits(['onSignOut', 'onVerified']);

const timeoutId = ref(null);
const checkCount = ref(0);
const countdown = ref(0);

const RESEND_COOLDOWN = 60; // 90 seconds cooldown

const isResendDisabled = computed(() => countdown.value > 0);

const sendVerificationEmail = async () => {
  if (currentUser.value && !isResendDisabled.value) {
    await sendEmailVerification(currentUser.value);
    localStorage.setItem('lastVerificationSent', Date.now().toString());
    startCountdown();
  }
}

const startCountdown = (initialValue = RESEND_COOLDOWN) => {
  countdown.value = initialValue;
  const countdownInterval = setInterval(() => {
    countdown.value--;
    if (countdown.value <= 0) {
      clearInterval(countdownInterval);
    }
  }, 1000);
}

const checkVerification = async () => {
  await currentUser.value?.reload();
  const verified = currentUser.value?.emailVerified || false;
  if (verified){
    emit('onVerified');
  }
}

const autoCheckVerification = () => {
  checkCount.value++;
  if (checkCount.value > 50) {
    clearTimeout(timeoutId.value);
    return;
  }

  checkVerification();
  timeoutId.value = setTimeout(autoCheckVerification, 5000);
}

onMounted(async () => {
  const lastSent = localStorage.getItem('lastVerificationSent');
  if (lastSent) {
    const elapsedTime = Math.floor((Date.now() - parseInt(lastSent)) / 1000);
    const remainingTime = Math.max(RESEND_COOLDOWN - elapsedTime, 0);

    if (remainingTime > 0) {
      startCountdown(remainingTime);
    } else {
      await sendVerificationEmail();
    }
  } else {
    await sendVerificationEmail();
  }

  autoCheckVerification();
});

onUnmounted(() => {
  if (timeoutId.value) {
    clearTimeout(timeoutId.value);
  }
});

</script>

<template>
  <div>
    <v-card-text v-if="!currentUser" class="text-center negative-margin">Log in to an existing account or create a new account.</v-card-text>
    <div v-if="currentUser">
      <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
      <div v-else-if="emailVerified">
        <Redirect v-if="currentUserUsername"  :to="computedRedirect"></Redirect>
<!-- todo: delete login complete?-->
<!--        <LoginComplete v-if="currentUserUsername" @onSignOut="signOut">-->
<!--        </LoginComplete>-->
        <CreateUsername v-else @onSuccess="usernameCreated" />
      </div>
      <EmailVerification v-else @onSignOut="signOut" @onVerified="emailVerified = true"/>
    </div>
    <div v-else>
      <v-card-text><div id="firebaseui-auth-container" class="simmer-firebase-ui"></div></v-card-text>
      <v-card-text>
        <div>
          <v-tooltip text="Use 'Sign in with email', enter your email, then click 'Trouble signing in?' to reset your password.">
            <template v-slot:activator="{ props }">
              <a href="javascript:void(0)" v-bind="props">Forgot password?</a>
            </template>
          </v-tooltip>
        </div>
        <v-tooltip text="Use 'Sign in with email', enter your email, then we will remind you whether you used Google or email.">
          <template v-slot:activator="{ props }">
            <a href="javascript:void(0)" v-bind="props">Forgot if you used Google or email?</a>
          </template>
        </v-tooltip>
      </v-card-text>
    </div>
  </div>
</template>

<script lang="ts" setup>

import { EmailAuthProvider, GoogleAuthProvider, signOut as firebaseSignOut, getAuth} from 'firebase/auth';
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import { useCurrentUser } from 'vuefire';
import {navigateTo} from "nuxt/app";
import CreateUsername from "~/components/CreateUsername.vue";
import LoginComplete from "~/components/LoginComplete.vue";
import EmailVerification from "~/components/EmailVerification.vue";
import Redirect from "~/components/utilities/Redirect.vue";

const currentUser = useCurrentUser();
const loading = ref(false);
const emailVerified = ref(currentUser.value?.emailVerified || false);
const route = useRoute();
const currentUserUsername = ref(await useCurrentUserUsername());

console.log(currentUserUsername, currentUserUsername.value);

const auth = getAuth();
auth.onAuthStateChanged(async (user) => {
  console.log("auth state changed", user, user?.emailVerified);
  loading.value = true;
  currentUserUsername.value = await useCurrentUserUsername();
  emailVerified.value = user?.emailVerified || false;
  loading.value = false;
});


watch(emailVerified, (newValue) => {
  console.log('Email verification status changed:', newValue);
});


const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(useFirebaseAuth());
const config = {
  signInOptions: [
    GoogleAuthProvider.PROVIDER_ID,
    EmailAuthProvider.PROVIDER_ID,
  ],
  signInFlow: 'popup',
  signInSuccessUrl: "/",
  callbacks: {
    signInSuccessWithAuthResult(): void {
      emailVerified.value = currentUser.value?.emailVerified || false;
      if (emailVerified.value) {
        const redirect = route.query.redirect as string | undefined;
        const myRoute = redirect ?? '/profile';

        // only redirect if has username

        // console.log(`LoginImpl::signInSuccessWithAuthResult: Redirecting to {${myRoute}}, ${currentUser}, ${currentUserUsername.value}`);
        // navigateTo(myRoute);
      }
      console.log(currentUser.value)
    },
  },
}

const computedRedirect = computed(() => {
  const redirect = route.query.redirect as string | undefined;
  if (redirect) {
    return redirect;
  }
  else{
    return '/profile';
  }
})

const signOut = async () => {
  loading.value = true;
  await firebaseSignOut(getAuth());
  startUI();
  loading.value = false;
}

const usernameCreated = async () => {
    loading.value = true;
    await currentUser.value?.reload();
    emailVerified.value = currentUser.value?.emailVerified || false;
    loading.value = false;
    const redirect = route.query.redirect as string | undefined;
    const myRoute = redirect ?? '/profile';

    // todo lowpri: global.
  if (window.HeaderLoginImplReloadProfile){
    window.HeaderLoginImplReloadProfile();
  }

    console.log(`LoginImpl::usernameCreated: Redirecting to {${myRoute}}`);
    navigateTo(myRoute);
}

const startUI = function(){
  if (!currentUser.value){
    // @ts-ignore
    ui.start("#firebaseui-auth-container", config);
  }
}
onMounted(() => {
  startUI();
})

</script>

<style scoped>
.negative-margin {
  margin-bottom: -16px;
}
</style>

<!-- firebase UI dark theme. deliberately not scoped -->
<!-- OK to ignore warnings about missing selectors -->
<style>
.simmer-firebase-ui a {
  color: #9e9eff !important;
}
.simmer-firebase-ui a:visited{
  color: #d0adf0 !important;
}

.simmer-firebase-ui .mdl-card{
  background-color:transparent !important;
}

.simmer-firebase-ui .firebaseui-title,.firebaseui-subtitle,.firebaseui-text{
  color:white;
}

.simmer-firebase-ui .firebaseui-label{
  color:grey;
}

.simmer-firebase-ui .firebaseui-textfield.mdl-textfield .firebaseui-input{
  border-color:grey;
  color:#ddd;
}

.simmer-firebase-ui .mdl-button--raised.mdl-button--colored {
  background: #f5f5f5;
  color: #202020
}

.simmer-firebase-ui .mdl-button--primary.mdl-button--primary{
  color:#f5f5f5
}

.simmer-firebase-ui .mdl-shadow--2dp{
    box-shadow: none;
}
</style>
